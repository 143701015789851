import React from "react";
import Footer from '../components/footer'
import "../css/career.css"
import NavBar from '../components/navbar'
export default class About extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }
    render() {
        return (
            <div>
                <div>
                    <NavBar />
                </div>
                <div className="about-banner py-20 w-full vh-half banner-color flex">
                    <div className="container mx-auto relative self-center">
                        <h1 className="about-banner-title text-7xl md:text-7xl text-white uppercase font-bold text-center care-frm">Career-form</h1>
                    </div>
                </div>
                <div class="my-14 mx-auto container">
                <iframe class="cfrm-wid" src="https://docs.google.com/forms/d/e/1FAIpQLScI8HkH7CtuKu7a3pFQ8kIIk-kE8kX9--1OMr6Th9OQ82JygQ/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        );
    }
}